import AndrewGogokhia from '../assets/images/company/team/AndrewGogokhia.png';
import PavelIvanov from '../assets/images/company/team/PavelIvanov.png';
import VitalySemyonov from '../assets/images/company/team/VitalySemyonov.png';
import FarizaRevuelta from '../assets/images/company/team/FarizaRevuelta.png';
import EdizBarakat from '../assets/images/company/team/EdizBarakat.png';
import DenisMann from '../assets/images/company/team/DenisMann.png';
import AbudulAziz from '../assets/images/company/team/AbudulAziz.png';
import ScottPepper from '../assets/images/company/team/ScottPepper.png';
import JoséphineAdeshina from '../assets/images/company/team/JoséphineAdeshina.png';
import GokceTekin from '../assets/images/company/team/GokceTekin.png';
import OmraneKayabas from '../assets/images/company/team/OmraneKayabas.png';
import CameroPalanisamy from '../assets/images/company/team/CameroPalanisamy.png';
import TonyShyrymbek from '../assets/images/company/team/TonyShyrymbek.png';
import MariaTricarico from '../assets/images/company/team/MariaTricarico.png';
import BeslindCuellar from '../assets/images/company/team/BeslindCuellar.png';
import AnastasiiaKuzniasova from '../assets/images/company/team/AnastasiiaKuzniasova.png';
import HassanBazar from '../assets/images/company/team/HassanBazar.png';
import AliEskini from '../assets/images/company/team/AliEskini.png';
import GokceLopes from '../assets/images/company/team/GokceLopes.png';
import KatarzynaChepelova from '../assets/images/company/team/KatarzynaChepelova.png';
import AnastasiaPetrova from '../assets/images/company/team/AnastasiaPetrova.png';
import FahimAlyi from '../assets/images/company/team/FahimAlyi.png';
import ToktamHarkati from '../assets/images/company/team/ToktamHarkati.png';
import KeremHulbert from '../assets/images/company/team/KeremHulbert.png';

const teamMembers = [
    {
        name: "Dr. Andrew Gogokhia",
        title: "Chief Executive Officer",
        abbreviation: "Ag",
        number: "01",
        ndbImage: AndrewGogokhia,
        position: "tl",
    },
    {
        name: "Pavel Ivanov",
        title: "Chief Technology Officer",
        abbreviation: "Pi",
        number: "11",
        ndbImage: PavelIvanov,
        position: "bl",
    },
    {
        name: "Vitaly Semyonov",
        title: "Chief Operating Officer",
        abbreviation: "Vs",
        number: "02",
        ndbImage: VitalySemyonov,
        position: "br",
    },
    {
        name: "Fariza Revuelta",
        title: "Chief Financial Officer",
        abbreviation: "Fr",
        number: "125",
        ndbImage: FarizaRevuelta,
        position: "tr",
    },
    {
        name: "Ediz Barakat",
        title: "Business Development",
        abbreviation: "Eb",
        number: "170",
        ndbImage: EdizBarakat,
        position: "tr",
    },
    {
        name: "Denis Mann",
        title: "Strategy Advisor",
        abbreviation: "Dm",
        number: "18",
        ndbImage: DenisMann,
        position: "tr",
    },
    {
        name: "Abudul Aziz",
        title: "Product Advisor",
        abbreviation: "Aa",
        number: "50",
        ndbImage: AbudulAziz,
        position: "tr",
    },
    {
        name: "Scott Pepper",
        title: "Blockchain Advisor",
        abbreviation: "Sp",
        number: "104",
        ndbImage: ScottPepper,
        position: "br",
    },
    {
        name: "Joséphine Adeshina",
        title: "Nuclear and Radiation Engineer",
        abbreviation: "Ja",
        number: "108",
        ndbImage: JoséphineAdeshina,
        position: "bl",
    },
    {
        name: "Gokce Tekin",
        title: "Nuclear Physicist",
        abbreviation: "Gt",
        number: "186",
        ndbImage: GokceTekin,
        position: "br",
    },
    {
        name: "Omrane Kayabas",
        title: "Mechanical Engineer",
        abbreviation: "Ok",
        number: "198",
        ndbImage: OmraneKayabas,
        position: "tr",
    },
    {
        name: "Camero Palanisamy",
        title: "Material Scientist",
        abbreviation: "Cp",
        number: "169",
        ndbImage: CameroPalanisamy,
        position: "tr",
    },
    {
        name: "Tony Shyrymbek",
        title: "Material Scientist",
        abbreviation: "Ts",
        number: "184",
        ndbImage: TonyShyrymbek,
        position: "tr",
    },
    {
        name: "Maria Tricarico",
        title: "Finance & Accounting Assistant",
        abbreviation: "Mt",
        number: "159",
        ndbImage: MariaTricarico,
        position: "br",
    },
    {
        name: "Beslind Cuellar",
        title: "Material Scientist",
        abbreviation: "Bc",
        number: "194",
        ndbImage: BeslindCuellar,
        position: "tr",
    },
    {
        name: "Kerem Hulbert",
        title: "Electrical and Electronics Engineer",
        abbreviation: "Kh",
        number: "200",
        ndbImage: KeremHulbert,
        position: "tr",
    },
    {
        name: "Anastasiia Kuzniasova",
        title: "UX/UI Designer",
        abbreviation: "Ak",
        number: "183",
        ndbImage: AnastasiiaKuzniasova,
        position: "br",
    },
    {
        name: "Hassan Bazar",
        title: "Backend Developer",
        abbreviation: "Hb",
        number: "178",
        ndbImage: HassanBazar,
        position: "tr",
    },
    {
        name: "Ali Eskini",
        title: "Frontend Developer",
        abbreviation: "Ae",
        number: "175",
        ndbImage: AliEskini,
        position: "tr",
    },
    {
        name: "Gokce Lopes",
        title: "Corporate Compliance ",
        abbreviation: "Gl",
        number: "145",
        ndbImage: GokceLopes,
        position: "tr",
    },
    {
        name: "Katarzyna Chepelova",
        title: "Compliance Assistant",
        abbreviation: "Kc",
        number: "188",
        ndbImage: KatarzynaChepelova,
        position: "tl",
    },
    {
        name: "Anastasia Petrova",
        title: "Executive Assistant",
        abbreviation: "Ap",
        number: "182",
        ndbImage: AnastasiaPetrova,
        position: "tl",
    },
    {
        name: "Fahim Alyi",
        title: "Advisor",
        abbreviation: "Fa",
        number: "185",
        ndbImage: FahimAlyi,
        position: "tl",
    },
    {
        name: "Prof. Toktam Harkati",
        title: "Advisor",
        abbreviation: "Th",
        number: "24",
        ndbImage: ToktamHarkati,
        position: "br",
    },
];

export default teamMembers;